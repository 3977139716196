import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MembershipDialogData,
  MembershipDialogResponse,
} from '@x/ecommerce-shop/app/membership/services/membership.service';

@Component({
  selector: 'x-membership-dialog',
  templateUrl: './membership-dialog.component.html',
  styleUrls: ['./membership-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembershipDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<MembershipDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MembershipDialogData,
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  action(action: MembershipDialogResponse) {
    this.dialogRef.close(action);
  }
}
