import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MembershipDialogComponent } from '@x/ecommerce-shop/app/membership/components/membership-dialog/membership-dialog.component';
import { Observable } from 'rxjs';

export interface MembershipAction {
  actionType: 'primary' | 'secondary';
  response: MembershipDialogResponse;
}
export interface MembershipDialogData {
  heading: string;
  message: string;
  actions?: MembershipAction[];
}
export type MembershipDialogResponse = 'subscribe' | 'info' | 'activate';

@Injectable()
export class MembershipService {
  constructor(private dialog: MatDialog) {}

  openMembershipDialog(
    data: MembershipDialogData,
  ): Observable<MembershipDialogResponse | undefined> {
    return this.dialog
      .open<MembershipDialogComponent, MembershipDialogData, MembershipDialogResponse>(
        MembershipDialogComponent,
        { data, panelClass: 'fullwidth-dialog-mobile' },
      )
      .afterClosed();
  }
}
