<x-shop-dialog [heading]="data.heading" (closeDialog)="closeDialog()">
  <p class="message" [innerHTML]="data.message"></p>

  <x-shop-dialog-actions *ngIf="data?.actions?.length">
    <ng-container *ngFor="let actionButton of data?.actions" xAction>
      <button
        *ngIf="actionButton.actionType === 'primary'"
        mat-flat-button
        color="primary"
        (click)="action(actionButton.response)"
      >
        <!--todo: localize-->
        {{ actionButton.response }}
      </button>
      <button
        *ngIf="actionButton.actionType === 'secondary'"
        mat-stroked-button
        color="primary"
        (click)="action(actionButton.response)"
      >
        {{ actionButton.response }}
      </button>
    </ng-container>
  </x-shop-dialog-actions>
</x-shop-dialog>
